<template>
  <div class="row mt-2" :class="[{'fieldNotUsed': value.length == 0}]">
    <div class="col-12 col-sm-5"><label class="d-block mt-2 text-sm-end">{{ label }}</label></div>
    <div :class="['col-6', 'col-sm-3', {'d-flex': isMultiple}]">
      <select v-if="isMultiple" class="form-control" v-model="indexPosition">
        <option value="">qualquer posição</option>
        <option value="0">1º elemento</option>
        <option value="1">2º elemento</option>
        <option value="2">3º elemento</option>
        <option value="3">4º elemento</option>
        <option value="4">5º elemento</option>
        <option value="5">6º elemento</option>
        <option value="6">7º elemento</option>
        <option value="7">8º elemento</option>
        <option value="8">9º elemento</option>
        <option value="9">10º elemento</option>
      </select>
      <select class="form-control" v-model="operator">
        <option value="">operador</option>
        <option v-for="operator in operators" :key="operator.value" :value="operator.value">{{ operator.label }}</option>
      </select>
    </div>
    <div class="col-6 col-sm-4">
      <input v-if="type === 'string'" type="text" class="form-control" :placeholder="'valor do(a) ' + label" v-model="value" />
      <input v-if="type === 'number'" type="number" class="form-control" :placeholder="'valor do(a) ' + label" v-model="value" />
      <select v-if="type === 'enum'" class="form-control" v-model="value">
        <option value="">valor do(a) {{ label }}</option>
        <option v-for="valueToChoose in valueOption" :key="valueToChoose" :value="valueToChoose">{{ valueToChoose }}</option>
      </select>
      <flat-pickr
        v-if="type === 'date'"
        :value="value"
        v-model="value"
        :config="configDateTime"
        :placeholder="'data em formato ano/mês/dia hora:minuto'"
        class="form-control flatpickr-input" />
      <label v-if="type === 'boolean'" class="form-label d-inline-block mt-2"><input type="radio" name="radiofield" v-model="value" :value="true" /> Sim</label>
      <label v-if="type === 'boolean'" class="form-label d-inline-block mt-2 ms-3"><input type="radio" name="radiofield" v-model="value" :value="false" /> Não</label>
    </div>
  </div>
</template>

<script>
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

export default {
  name: 'ConditionField',
  components: {
    flatPickr
  },
  props: {
    modelValue: [String, Number, Object],
    entity: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    type: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    valueOption: {
      type: Array,
      required: false
    },
    parentNode: {
      type: String,
      required: false
    },
    isMultiple: {
      type: Boolean,
      required: false,
      default: () => false
    }
  },
  data() {
    return {
      operator: this.modelValue !== undefined && this.modelValue.operator !== undefined ? this.modelValue.operator : '',
      indexPosition: this.modelValue !== undefined && this.modelValue.path !== undefined && this.modelValue.path.match(/\[([0-9]+)\]/) ? this.modelValue.path.match(/\[([0-9]+)\]/)[1] : '',
      value: this.modelValue !== undefined && this.modelValue.value !== undefined ? this.modelValue.value : '',
      operatorOptions: {
        string: [
          { label: 'igual à', value: 'equal' },
          { label: 'diferente de', value: 'notEqual' }
        ],
        enum: [
          { label: 'igual à', value: 'equal' },
          { label: 'diferente de', value: 'notEqual' }
        ],
        boolean: [
          { label: 'igual à', value: 'equal' },
          { label: 'diferente de', value: 'notEqual' }
        ],
        date: [
        { label: 'mais recente que', value: 'newerThan' },
        { label: 'mais antigo que', value: 'olderThan' },
        ],
        number: [
          { label: 'igual à', value: 'equal' },
          { label: 'diferente de', value: 'notEqual' },
          { label: 'menor que', value: 'lessThan' },
          { label: 'menor ou igual que', value: 'lessThanInclusive' },
          { label: 'maior que', value: 'greaterThan' },
          { label: 'maior ou igual que', value: 'greaterThanInclusive' },
        ]
      },
      configDateTime: {
        wrap: true,
        allowInput: false,
        enableTime: true,
        dateFormat: "Y-m-dTH:i:S",
        altInput: true,
        altFormat: "d/m/Y H:i"
      }
    }
  },
  computed: {
    operators() {
      if (this.operatorOptions[this.type]) {
        return this.operatorOptions[this.type];
      }
      return [];
    }
  },
  watch: {
    value () {
      if (this.operator.length && this.value.toString().length) {
        const path = ['$'];
        if (this.parentNode) {
          if (this.isMultiple && this.indexPosition.toString().length) {
            path.push(`${this.parentNode}[${this.indexPosition}]`);  
          } else {
            path.push(this.parentNode);
          }
        }
        path.push(this.name);
        this.$emit('update:modelValue', {
          fact: this.entity,
          operator: this.operator,
          value: this.value,
          path: path.join('.')
        });
      }
    }
  }
}
</script>

<style scoped>
.fieldNotUsed label,
.fieldNotUsed select,
.fieldNotUsed input {
  opacity: 0.35;
}
</style>

<script>
  import Multiselect from "@vueform/multiselect";
  import "@vueform/multiselect/themes/default.css";
  import { overlay } from "@/state/helpers";
  import Layout from "@/layouts/main.vue";
  import PageHeader from "@/components/page-header";
  import SettingsSide  from "@/components/settings-side.vue";
  import EmptyTableResult from "@/components/empty-table-result";
  import CustomField from "@/components/custom-field";
  import ConditionField from "@/components/condition-field";
  import appConfig from "@/../app.config";
  import Swal from "sweetalert2";
  import { callUrl } from '@/helpers/api-apolo';
  import { saError } from '@/helpers/global-functions';
  import * as helpers from '@/helpers/helper-functions';

  export default {
    page: {
      title: "Automações",
      meta: [{
        name: "description",
        content: appConfig.description
      }],
    },
    data() {
      return {
        title: "Automações",
        items: [{
            text: "Configurações",
            href: "/"
          },
          {
            text: "Automações",
            active: true
          },
        ],
        filters: {
          query: '',
          trigger: ''
        },
        page: 1,
        perPage: 10,
        dataList: [],
        pipelines: [],
        automationConfig: {},
        conditionEnable: false,
        conditionType: 'all',
        conditionValues: [],
        activeAutomation: {
          trigger: '',
          actions: [{name: '', properties: {}}]
        }
      }
    },
    components: {
      Layout,
      PageHeader,
      Multiselect,
      EmptyTableResult,
      SettingsSide,
      CustomField,
      ConditionField
    },
    computed: {
      pages() {
        if (this.page == 1 && !this.dataList.length) {
          return [];
        }
        if (this.page == 1 && this.dataList.length < this.perPage) {
          return [1];
        }
        if (this.page == 1 && this.dataList.length == this.perPage) {
          return [1, 2];
        }
        if (this.page > 1 && (!this.dataList.length || this.dataList.length < this.perPage)) {
          return [this.page - 1, this.page];
        }
        return [this.page - 1, this.page, this.page + 1];
      },
      triggersAllowed() {
        if (!this.automationConfig.triggers) {
            return [];
        }
        return [{ value: '', label: 'Escolha um gatilho' }].concat(this.automationConfig.triggers.map(trigger => ({ value: trigger.name, label: trigger.label })));
      },
      actionsAllowed() {
        if (!this.activeAutomation.trigger) {
            return [];
        }
        const trigger = this.automationConfig.triggers.filter(trigger => trigger.name === this.activeAutomation.trigger)[0];
        return trigger.actions.map(actionName => {
            const action = this.automationConfig.actions.filter(action => action.name === actionName)[0];
            return {
                value: action.name,
                label: action.label
            };
        });
      },
      entitiesAllowed() {
        if (!this.activeAutomation.trigger) {
            return [];
        }
        const trigger = this.automationConfig.triggers.filter(trigger => trigger.name === this.activeAutomation.trigger)[0];
        return this.automationConfig.entities.filter(entity => {
          return trigger.entities.filter(entityName => entity.name === entityName).length > 0;
        });
      },
      parameterActions() {
        if (!this.activeAutomation.actions || this.activeAutomation.actions[0].name.length == 0) {
            return [];
        }
        return this.automationConfig.actions.filter(action => action.name === this.activeAutomation.actions[0].name)[0].parameters;
      }
    },
    watch: {
      page() {
        this.fetchData();
      }
    },
    methods: {
      ...overlay,
      ...helpers,
      translateTrigger(triggerName) {
        if (this.automationConfig.triggers) {
            return this.automationConfig.triggers.filter(trigger => trigger.name == triggerName)[0].label;
        }
        return triggerName;
      },
      translateAction(actionName) {
        if (this.automationConfig.actions) {
            return this.automationConfig.actions.filter(action => action.name == actionName)[0].label;
        }
        return actionName;
      },
      triggerChanged() {
        this.conditionType = 'all';
        this.conditionValues = [];
        this.conditionEnable = false;
        this.activeAutomation.conditions = {};
        this.activeAutomation.actions[0].name = '';
        this.actionChanged();
      },
      actionChanged() {
        this.activeAutomation.actions[0].properties = {};
      },
      buildCustomField(parameter) {
        const field = {
            ...parameter,
            name: parameter.label
        };
        if (parameter.name == 'pipelineId') {
            field.type = 'single_enum';
            field.value_options = this.pipelines.map(pipeline => pipeline.id);
        }
        return field;
      },
      search() {
        if (this.page !== 1) {
          this.page = 1;
        } else {
          this.fetchData();
        }
      },
      async fetchData() {
        try {
          this.changeShowOverlay({show: true});
          this.dataList = [];
          const params = [`page=${this.page}`, `limit=${this.perPage}`, `order=created_at:1`];
          if (this.filters.query.length > 2) {
            params.push(`query=${this.filters.query}`);
          }
          if (this.filters.trigger?.length > 0) {
            params.push(`trigger=${this.filters.trigger}`);
          }
          const dataM = await callUrl({}, `/automation?${params.join('&')}`, 'get');
          if (dataM && dataM.length) {
            this.dataList = dataM;
          }
        } catch (error) {
          console.log(error.message);
        }
        this.changeShowOverlay({show: false});
      },
      async fetchConfig() {
        try {
          this.automationConfig = {};
          const dataM = await callUrl({}, '/automation/configuration', 'get');
          if (dataM) {
            this.automationConfig = dataM;
          }
        } catch (error) {
          console.log(error.message);
        }
      },
      async fetchPipelines() {
        try {
          this.pipelines = [];
          const dataM = await callUrl({}, '/pipeline', 'get')
          if (dataM && dataM.length) {
            this.pipelines = dataM;
          }
        } catch (error) {
          console.log(error.message);
        }
      },
      async save() {
        if (!this.activeAutomation.name || !this.activeAutomation.name.length) {
          saError('O nome da automação é obrigatório');
          return false;
        }
        if (this.activeAutomation.active === undefined || (this.activeAutomation.active !== true && this.activeAutomation.active !== false)) {
          saError('O status da automação é obrigatório');
          return false;
        }
        if (!this.activeAutomation.trigger || !this.activeAutomation.trigger.length) {
          saError('O gatilho da automação é obrigatório');
          return false;
        }
        if (this.conditionEnable && this.conditionType !== 'all' && this.conditionType !== 'any') {
          saError('O tipo de condição é obrigatório');
          return false;
        }
        const conditions = this.conditionValues.filter(condition => condition !== undefined && condition.value !== undefined);
        if (this.conditionEnable && !conditions.length) {
          saError('Ao menos uma condição é obrigatória');
          return false;
        }
        if (!this.activeAutomation.actions[0].name.length) {
          saError('A ação da automação é obrigatória');
          return false;
        }
        const action = this.automationConfig.actions.filter(action => action.name == this.activeAutomation.actions[0].name)[0];
        for (const key in action.parameters) {
          if (action.parameters[key].required
            && (
              !this.activeAutomation.actions[0].properties
              || !Object.keys(this.activeAutomation.actions[0].properties).length
              || !this.activeAutomation.actions[0].properties[action.parameters[key].name]
              || !this.activeAutomation.actions[0].properties[action.parameters[key].name].length
            )
          ) {
            saError(`O parâmetro "${action.parameters[key].label}" da ação é obrigatório`);
            return false;
          }
        }
        if (conditions.length > 0) {
          this.activeAutomation.conditions = {};
          this.activeAutomation.conditions[this.conditionType] = conditions;
        }
        
        try {
          this.changeShowOverlay({show: true});
          if (this.activeAutomation.id && this.activeAutomation.id.length > 0) {
            await callUrl(this.activeAutomation, `/automation/${this.activeAutomation.id}`, 'put');
            Swal.fire("Sucesso!", "Automação atualizada com sucesso", "success");
          } else {
            await callUrl(this.activeAutomation, '/automation', 'post');
            Swal.fire("Sucesso!", "Automação criada com sucesso", "success");
          }
          document.getElementById('closemodal').click();
          this.search();
        } catch (error) {
          saError(error.message);
        } finally {
          this.changeShowOverlay({show: false});
        }
      },
      async deleteData(event) {
        Swal.fire({
            title: "Você tem certeza que deseja remover esta automação?",
            text: "Não será possível reverter esta ação!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Sim, remover!",
            cancelButtonText: "Cancelar",
            reverseButtons: true,
            buttonsStyling: false,
            customClass: {
              actions: 'd-flex justify-content-center',
              confirmButton: 'btn btn-success flex-grow-1',
              cancelButton: 'btn btn-outline-dark flex-grow-1'
            }
        }).then(async (result) => {
          if (result.value) {
            try {
                this.changeShowOverlay({show: true});
                await callUrl({}, '/automation/' + event.id, 'delete');
                this.changeShowOverlay({show: false});
                Swal.fire("Sucesso!", "Automação removida com sucesso", "success");
                this.search();
            } catch (error) {
                saError(error.message);
            } finally {
                this.changeShowOverlay({show: false});
            }
          }
        });
      },
      searchConditionSaved(entity, field, parent, isMultiple) {
        if (this.activeAutomation.conditions && this.activeAutomation.conditions[this.conditionType]) {
          const condition = this.activeAutomation.conditions[this.conditionType].filter(condition => {
            const path = ['$'];
            if (parent && parent.length > 0) {
              if (isMultiple) {
                path.push(parent + '.*');
              } else {
                path.push(parent);
              }
            }
            path.push(field);
            if (isMultiple) {
              const regex = new RegExp('\\' + path.join('\\.'));
              return condition.fact == entity && regex.test(condition.path);
            }
            return condition.fact == entity && condition.path == path.join('.');
          });
          if (condition.length > 0) {
            return condition[0];
          }
        }
        return undefined;
      },
      viewItem(item) {
        this.activeAutomation = item
        this.conditionType = Object.keys(this.activeAutomation.conditions)[0];
        this.conditionValues = [];
        for (const key in this.entitiesAllowed) {
          for (const idx in this.entitiesAllowed[key].properties) {
            const property = this.entitiesAllowed[key].properties[idx];
            if (property.type == 'object') {
              for (const jdx in property.properties) {
                this.conditionValues[idx + '' + jdx] = this.searchConditionSaved(
                  this.entitiesAllowed[key].name,
                  property.properties[jdx].name,
                  property.name
                );
              }
            } else if (property.type == 'array') {
              for (const jdx in property.item[0].properties) {
                this.conditionValues[idx + '' + jdx] = this.searchConditionSaved(
                  this.entitiesAllowed[key].name,
                  property.item[0].properties[jdx].name,
                  property.name,
                  true
                );
              }
            } else {
              this.conditionValues[idx] = this.searchConditionSaved(this.entitiesAllowed[key].name, property.name);
            }            
          }
        }
        this.conditionEnable = this.conditionValues.length > 0;
      },
      openModal() {
        this.activeAutomation = {
          trigger: '',
          actions: [{name: '', properties: {}}],
          active: true
        };
        this.conditionType = 'all';
        this.conditionValues = [];
        this.conditionEnable = false;
      }
    },
    async mounted() {
        this.changeShowOverlay({show: true});
        await this.fetchConfig();
        await this.fetchData();
        this.fetchPipelines();
    }
  };
</script>

<style scoped>
.blockedField > label {
    opacity: 0.5;
}
.blockedField > div {
    background-color: #f3f3f3 !important;
}
@media only screen and (max-width: 500px) {
    .card-header h5 { display: none; }
}
@media only screen and (max-width: 390px) {
    .card-header button i { display: none; }
}
</style>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
      <div class="row">
        <div class="col-sm-12 col-lg-2 col-md-3">
          <SettingsSide :actualRoute="'integrations'"></SettingsSide>
        </div>
        <div class="col-sm-12 col-lg-10 col-md-9">
          <div class="card">
            <div class="card-header border-0">
              <div class="d-flex align-items-center">
                <h5 class="card-title mb-0 flex-grow-1">Automações</h5>
                <div class="flex-shrink-0">
                  <button class="btn btn-success add-btn ms-3" data-bs-toggle="modal" href="#showmodal" @click="openModal">
                    <i class="ri-add-line align-bottom me-1"></i> Criar nova Automação
                  </button>
                </div>
              </div>
            </div>

            <div class="card-body bg-soft-light border border-dashed border-start-0 border-end-0">
                <form>
                    <div class="row g-3">
                        <div class="col-12 col-sm-5 col-md-6">
                        <div class="search-box">
                            <input type="text" class="form-control search" v-model="filters.query" placeholder="Busca por algum termo...">
                            <i class="ri-search-line search-icon"></i>
                        </div>
                        </div>

                        <div class="col-12 col-sm-4 col-md-4">
                        <div class="input-light">
                            <Multiselect class="form-control" v-model="filters.trigger" :close-on-select="true" :searchable="true"
                            :create-option="true"
                            :options="[{ value: '', label: 'Todos gatilhos' }].concat((automationConfig.triggers??[]).map(trigger => ({value:trigger.name, label:trigger.label})))" />
                        </div>
                        </div>

                        <div class="col-12 col-sm-3 col-md-2">
                        <button type="button" class="btn btn-primary w-100" @click="search()">
                            <i class="mdi mdi-magnify me-1 align-bottom"></i> Buscar
                        </button>
                        </div>
                    </div>
                </form>
            </div>

            <div class="card-body">
            <div>
              <div class="table-responsive table-card">
                <table class="table table-striped align-middle table-nowrap">
                  <thead class="table-primary">
                    <tr>
                      <th class="sort text-uppercase">ID</th>
                      <th class="sort text-uppercase">Nome</th>
                      <th class="sort text-uppercase">Ativo</th>
                      <th class="sort text-uppercase">Gatilho</th>
                      <th class="sort text-uppercase">Ação</th>
                      <th class="sort text-uppercase">Criado em</th>
                      <th class="sort text-uppercase">Atualizado em</th>
                      <th class="sort text-uppercase">Ações</th>
                    </tr>
                  </thead>
                  <tbody class="list form-check-all">
                    <tr v-for="(item, index) of dataList" :key="index">
                      <td>{{item.code}}</td>
                      <td>{{item?.name}}</td>
                      <td>{{item.active ? 'Sim' : 'Não'}}</td>
                      <td>{{translateTrigger(item.trigger)}}</td>
                      <td>{{translateAction(item.actions[0].name)}}</td>
                      <td>{{translateDateOnly(item.created_at)}} <small class="text-muted">{{translateTimeOnly(item.created_at)}}</small></td>
                      <td v-if="item.updated_at">{{translateDateOnly(item.updated_at)}} <small class="text-muted">{{translateTimeOnly(item.updated_at)}}</small></td>
                      <td v-else>-</td>
                      <td>
                        <div class="dropdown">
                          <button class="btn btn-soft-secondary btn-sm dropdown" type="button" data-bs-toggle="dropdown"
                            aria-expanded="false">
                            <i class="ri-more-fill align-middle"></i>
                          </button>
                          <ul class="dropdown-menu dropdown-menu-end">
                            <li>
                              <a class="dropdown-item" data-bs-toggle="modal" href="#showmodal" @click.prevent="viewItem(item)" style="cursor:pointer">
                                <i class="ri-pencil-fill align-bottom me-2 text-muted"></i> Editar
                              </a>
                            </li>
                            <li class="dropdown-divider"></li>
                            <li>
                              <a class="dropdown-item remove-item-btn" @click.prevent="deleteData(item)" style="cursor:pointer">
                                <i class="ri-delete-bin-fill align-bottom me-2 text-muted"></i> Remover
                              </a>
                            </li>
                          </ul>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <EmptyTableResult v-show="dataList.length == 0" />

              </div>
              <div class="d-flex justify-content-end mt-3">
                <div class="pagination-wrap hstack gap-2">
                  <a class="page-item pagination-prev" href="#" @click="--page" v-if="page > 1">Anterior</a>
                  <a class="page-item pagination-prev disabled" href="#" v-if="dataList.length && page == 1" @click.prevent="">Anterior</a>
                  <ul class="pagination listjs-pagination mb-0">
                    <li v-for="pageNumber in pages" :key="'page' + pageNumber" @click="page = pageNumber"
                      :class="{active: pageNumber == page, disabled: pageNumber != page}">
                      <a class="page" href="#">{{ pageNumber }}</a>
                    </li>
                  </ul>
                  <a class="page-item pagination-next" href="#" @click="++page" v-if="dataList.length == perPage">Próxima</a>
                  <a class="page-item pagination-next disabled" href="#" @click.prevent="" v-if="dataList.length && dataList.length < perPage">Próxima</a>
                </div>
              </div>

                <!-- Modal -->
                <div class="modal fade zoomIn" id="showmodal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered modal-lg">
                        <div class="modal-content border-0">
                            <div class="modal-header p-3 bg-soft-info">
                                <h5 v-if="!activeAutomation.id" class="modal-title" id="exampleModalLabel">Criar nova Automação</h5>
                                <h5 v-if="activeAutomation.id" class="modal-title" id="exampleModalLabel">Automação {{activeAutomation.code}}</h5>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="close-modal"></button>
                            </div>
                            <form id="addform">
                                <div class="modal-body">
                                    <div class="row">
                                        <div class="col-lg-8">
                                            <label for="fieldName" class="form-label">Nome *</label>
                                            <input type="text" v-model="activeAutomation.name" id="fieldName" class="form-control" placeholder="Nome da automação" />
                                        </div>
                                        <div class="col-lg-4">
                                            <label class="form-label d-block">Ativa *</label>
                                            <label class="me-3 mt-2">
                                                <input
                                                    type="radio"
                                                    name="fieldActive"
                                                    :value="true"
                                                    v-model="activeAutomation.active"
                                                    :checked="activeAutomation.active == true"
                                                    class="form-check-input" /> Sim
                                            </label>
                                            <label class="me-3 mt-2">
                                                <input
                                                    type="radio"
                                                    name="fieldActive"
                                                    :value="false"
                                                    v-model="activeAutomation.active"
                                                    :checked="activeAutomation.active == false"
                                                    class="form-check-input" /> Não
                                            </label>
                                        </div>
                                    </div>
                                    <div class="row mt-4">
                                        <div class="col-lg-12">
                                            <label for="fieldDescription" class="form-label">Descrição</label>
                                            <textarea v-model="activeAutomation.description" id="fieldDescription" class="form-control" placeholder="Descrição da automação"></textarea>
                                        </div>
                                    </div>
                                    <div class="row mt-4">
                                      <div class="col-lg-12">
                                        <label for="fieldTrigger" class="form-label">Gatilho *</label>
                                        <Multiselect id="fieldTrigger" v-model="activeAutomation.trigger" :close-on-select="true" :searchable="false" :create-option="false" :options="triggersAllowed" @change="triggerChanged" />
                                      </div>
                                    </div>
                                    <div class="row mt-4">
                                      <div class="col-12 col-lg-6">
                                        <label class="form-label me-3">Possui Condição:</label>
                                        <label class="me-3 mt-2">
                                          <input
                                            type="radio"
                                            name="fieldConditionEnable"
                                            :value="true"
                                            v-model="conditionEnable"
                                            class="form-check-input" /> Sim
                                        </label>
                                        <label class="me-3 mt-2">
                                          <input
                                            type="radio"
                                            name="fieldConditionEnable"
                                            :value="false"
                                            v-model="conditionEnable"
                                            class="form-check-input" /> Não
                                        </label>
                                      </div>
                                      <div class="col-12 col-lg-6" v-if="conditionEnable">
                                        <label class="form-label me-3">Tipo de Condição:</label>
                                        <label class="me-3 mt-2">
                                          <input
                                            type="radio"
                                            name="fieldConditionType"
                                            :value="'all'"
                                            v-model="conditionType"
                                            class="form-check-input" /> Todas
                                        </label>
                                        <label class="me-3 mt-2">
                                          <input
                                            type="radio"
                                            name="fieldConditionType"
                                            :value="'any'"
                                            v-model="conditionType"
                                            class="form-check-input" /> Qualquer uma
                                        </label>
                                      </div>
                                      <div v-if="conditionEnable">
                                        <div class="row mt-2" v-for="entity in entitiesAllowed" :key="'entity_' + entity.name">
                                          <div class="col-lg-12"><label class="form-label">Condições sobre o(a) {{ entity.label }}</label></div>
                                          <div v-for="(property, idx) in entity.properties" :key="'condition_group_' + entity.name + '_' + property.name">
                                            <ConditionField
                                              v-if="property.type != 'object' && property.type != 'array'"
                                              :key="activeAutomation.id + 'condition_' + entity.name + '_' + property.name"
                                              v-model="conditionValues[idx]"
                                              :entity="entity.name"
                                              :label="property.label"
                                              :type="property.type"
                                              :name="property.name"
                                              :value-option="property.values" />
                                            <div v-if="property.type == 'object'">
                                              <ConditionField
                                                v-for="(innerProperty, jdx) in property.properties"
                                                :key="activeAutomation.id + 'condition_' + entity.name + '_' + property.name + '_' + innerProperty.name"
                                                v-model="conditionValues[idx + '' + jdx]"
                                                :entity="entity.name"
                                                :label="innerProperty.label + ' do(a) ' + property.label"
                                                :type="innerProperty.type"
                                                :name="innerProperty.name"
                                                :parent-node="property.name"
                                                :value-option="innerProperty.values" />
                                            </div>
                                            <div v-if="property.type == 'array'">
                                              <ConditionField
                                                v-for="(innerProperty, jdx) in property.item[0].properties"
                                                :key="activeAutomation.id + 'condition_' + entity.name + '_' + property.name + '_' + innerProperty.name"
                                                v-model="conditionValues[idx + '' + jdx]"
                                                :entity="entity.name"
                                                :label="innerProperty.label + ' do(a) ' + property.label"
                                                :type="innerProperty.type"
                                                :name="innerProperty.name"
                                                :parent-node="property.name"
                                                :is-multiple="true"
                                                :value-option="innerProperty.values" />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="row mt-4">
                                      <div class="col-lg-12" :class="[{'blockedField': actionsAllowed.length == 0}]">
                                        <label for="fieldAction" class="form-label">Ação *</label>
                                        <Multiselect id="fieldAction" v-model="activeAutomation.actions[0].name" :disabled="actionsAllowed.length == 0" :close-on-select="true" :searchable="true" :create-option="false" :options="actionsAllowed" @change="actionChanged" />
                                      </div>
                                    </div>
                                    <div class="row mt-4" v-if="parameterActions.length > 0">
                                      <div class="col-lg-12"><label class="form-label">Parâmetros da Ação</label></div>
                                      <div class="col-lg-6" v-for="parameter in parameterActions" :key="parameter.name">
                                        <CustomField
                                          :key="'property_' + parameter.name"
                                          :field="buildCustomField(parameter)"
                                          v-model="activeAutomation.actions[0].properties[parameter.name]" />
                                      </div>
                                    </div>
                                </div>
                                <div class="modal-footer ">
                                  <div class="hstack gap-2 justify-content-end">
                                    <button type="button" class="btn btn-light" data-bs-dismiss="modal" id="closemodal">Fechar</button>
                                    <button v-if="!activeAutomation.id" type="submit" class="btn btn-success" id="add-btn" @click.prevent="save">Criar</button>
                                    <button v-if="activeAutomation.id" type="submit" class="btn btn-success" id="add-btn" @click.prevent="save">Atualizar</button>
                                  </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <!--end modal-->
            </div>

          </div>
        </div>

      </div>
    </div>
  </Layout>
</template>
